import Vue from 'vue'
import HoneybadgerVue from '@honeybadger-io/vue'

export default ({ $config: { HONEYBADGER_API_KEY, NUXT_REVISION, APP_ENV } }) => {
  const config = {
    apiKey: HONEYBADGER_API_KEY,
    environment: APP_ENV,
    revision: NUXT_REVISION
  }

  Vue.use(HoneybadgerVue, config)
}

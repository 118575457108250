const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['redirect_signed_in'] = require('../middleware/redirect_signed_in.js')
middleware['redirect_signed_in'] = middleware['redirect_signed_in'].default || middleware['redirect_signed_in']

middleware['static_page'] = require('../middleware/static_page.js')
middleware['static_page'] = middleware['static_page'].default || middleware['static_page']

export default middleware

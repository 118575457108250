import Vue from 'vue'

export default ({ $config: { WORKING_HOURS_TEXT } }) => {
  Vue.prototype.$currency_format = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
  Vue.prototype.$defaultVariantImage = require('../../shared/assets/images/fallback/variant_default.png')
  Vue.prototype.$app_name = 'scout'
  Vue.prototype.$phoneMask = '(###) ###-####'
  Vue.prototype.$creditCardMask = '#### #### #### #### ###'
  Vue.prototype.$workingHoursText = WORKING_HOURS_TEXT
  Vue.prototype.$breadCurrency = 'USD'
}

import { get, isEmpty, cloneDeep, forEach, compact, map, omit } from "lodash"

const productData = {
    namespaced: true,
    state: {
        products: {},
        current_product: ''
    },
    getters: {
        getProductCare: state => id => {
            const product = state.products[state.current_product] || {}
            const variant = get(product, `variants.${id}`, {})
            const result = [variant, product].find(x => {
                if (x?.product_care_fallback) {
                    return false
                }

                if (!x.product_care) {
                    return false
                }

                const productCare = x.product_care
                return [productCare.text, productCare.videos_links, productCare.product_care_attachments].some(x => isEmpty(x))
            })

            return result?.product_care
        },
        getProduct: state => {
            return state.products[state.current_product] ? state.products[state.current_product] : {}
        },
        getId: state => {
            return state.products[state.current_product] ? state.products[state.current_product]['id'] : ''
        },
        getProductId: state => {
            var variants = Object.values(state.products[state.current_product]?.variants ?? {})
            return variants[0]?.product_id
        },
        getBreadcrumbs: state => {
            return state.products[state.current_product] ? state.products[state.current_product]['breadcrumbs'] : []
        },
        getName: state => {
            return state.products[state.current_product] ? state.products[state.current_product]['name'] : ''
        },
        getVariantName: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['name'] : null
        },
        getSlug: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['slug'] : ''
        },
        getDiscontinued: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['discontinued'] : false
        },
        getPurchasable: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['purchasable'] : false
        },
        getSku: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['sku'] : ''
        },
        getBaseSku: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['base_sku'] : []
        },
        getImages: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['images'] : []
        },
        getModel3dLink: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['model_3d_link'] : ''
        },
        onSale: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['sale_display'] : false
        },
        getSalePrice: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['sale_price'] : ''
        },
        getSaleDateEnd: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['sale_date_end'] : ''
        },
        getWarranty: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['warranty'] : ''
        },
        getShipping: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['shipping'] : ''
        },
        getReturns: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['returns'] : ''
        },
        getDescription: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['description'] : ''
        },
        getProperties: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['variant_properties_without_filters'] : ''
        },
        getVariantProperties: state => id => {
            return state.products[state.current_product]?.['variants']?.[id]?.['variant_properties'] ?? []
        },
        checkForProperties: state => (id, property) => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['variant_properties'].filter(variantProperty => variantProperty.property?.name == property).lenght > 1 : false
        },
        getQuantity: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? parseInt(state.products[state.current_product]['variants'][id]['quantity']) : 0
        },
        getBackorderable: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['backorderable'] : ''
        },
        getBackorderableDate: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['backorderable_date'] : ''
        },
        getVideos: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['videos_links'] : []
        },
        getVideosIds: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['videos_ids'] : []
        },
        getSeoTitle: state => {
            return state.products[state.current_product] ? state.products[state.current_product]['search_engine_optimization']['title'] : ''
        },
        getSeoDescription: state => {
            return state.products[state.current_product] ? state.products[state.current_product]['search_engine_optimization']['description'] : ''
        },
        getMainVariantId: state => {
            let product = state.products[state.current_product]
            product = product == undefined ? {variants: {}} : product

            let variants = Object.values(product['variants'])
            if (variants.length == 1) {
                return variants[0]['id']
            }

            let main_variant = variants.filter(v => v['main'])
            return main_variant.length == 0 ? '' : main_variant[0]['id']
        },
        getMainVariantSlug: state => {
            return state.products[state.current_product] ? state.products[state.current_product].main_variant_slug : ''
        },
        getOptions: state => (active = true) => {
            let currentProduct = state.products[state.current_product]
            if (!currentProduct) { return {} }

            let options = currentProduct['available_options']
            let entries = map(options, (value, key) => (!active || value._active) ? [key, omit(value, '_active')] : null)
            entries = compact(entries)

            return Object.fromEntries(entries)
        },
        getMultivariantOptions: state => (active = true) => {
            if(!state.products[state.current_product]) { return {} }

            let multivariantOptions = cloneDeep(state.products[state.current_product]['multivariant_options'])

            forEach(multivariantOptions, (values, key) => {
                multivariantOptions[key] = values.filter(value => !active || value.active)
            })

            return multivariantOptions
        },
        getPrice: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['price'] : ''
        },
        getMsrpPrice: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['msrp_price'] : ''
        },
        getAttachments: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['attachments'] : []
        },
        variantExist: state => id => {
            return state.products[state.current_product]['variants'][id] != undefined
        },
        getVariant: (state, getters) => id => {
            return getters.variantExist(id) ? state.products[state.current_product]['variants'][id] : {}
        },
        productExistWithVariant: state => (slug, variant_id) => {
            let products = Object.values(state.products)

            let variantWithSlugExists = products.flatMap(e => Object.values(e.variants)).find(e => e.slug === slug)
            if (variantWithSlugExists) { return true }

            products = products.map( product => { return {slug: product['slug'], id: product['id']} } )
            let product_index = products.map( product => product['slug'] ).indexOf(slug)

            if (products[product_index] == undefined) {
                return false
            } else {
                return product_index >= 0 &&
                    state.products[products[product_index]['id']]['variants'][variant_id] != undefined
            }
        },
        getSupplierName: state => {
            return state.products[state.current_product] ? state.products[state.current_product]['supplier_name'] : 'sdfs dfsd '
        },
        getCategoryName: state => {
            return state.products[state.current_product] ? state.products[state.current_product]['category_name'] : ''
        },
        getCategoryPermalink: state => {
            return state.products[state.current_product] ? state.products[state.current_product]['category_permalink'] : ''
        },
        getSupplierSlug: state => {
            return state.products[state.current_product] ? state.products[state.current_product]['supplier_slug'] : ''
        },
        getProductVideosIds: state => {
            return state.products[state.current_product] ? state.products[state.current_product]['product_videos_ids'] : []
        },
        getCustomMadeInfo: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]
                && state.products[state.current_product]['variants'][id]['custom_made_info'])
                ? state.products[state.current_product]['variants'][id]['custom_made_info'] : null
        },
        getVariantsNames: state => {
            return state.products[state.current_product] ? state.products[state.current_product]['variants_names'] : []
        },
        getShippingCategory: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['shipping_category'] : ''
        },
        getNonReturnable: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['non_returnable'] : false
        },
        getDisplayNonReturnable: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['display_non_returnable'] : false
        },
        getEstimatedAvailabilityType: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['estimated_availability_type'] : ''
        },
        getEstimatedAvailabilityValue: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['estimated_availability_value'] : ''
        },
        getMadeToOrder: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['made_to_order'] : false
        },
        getLeadTime: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['lead_time'] : ''
        },
        getGiftCard: state => {
            return state.products[state.current_product] ? state.products[state.current_product]['gift_card'] : false
        },
        getFreeShipCode: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['free_ship_code'] : ''
        },
        getPromoCode: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['promo_code'] : ''
        },
        getCuratedCollection: state => id => {
            return (state.products[state.current_product] && state.products[state.current_product]['variants'][id]) ? state.products[state.current_product]['variants'][id]['curated_collection'] : null
        },
        getRelatedCollections: state => id => {
            return state.products[state.current_product]?.variants?.[id]?.related_collections ?? []
        }
    },
    mutations: {
        setCurrentProduct(state, payload) {
            let product = Object.values(state.products)
                                .find(product => {
                                    let variantWithSlug = Object.values(product.variants)
                                                                .find(variant => variant.slug === payload)
                                    return variantWithSlug || product.slug === payload
                                })
            state.current_product = get(product, 'id', '')
        },
        initProduct(state, payload) {
            // set product
            let product =  Object.assign({}, payload)
            delete product['variant']

            // set variant
            let variant = payload['variant']

            //add product
            state.products[product['id']] = product
            state.current_product = product['id']

            // adding variant
            state.products[product['id']] == undefined ? state.products[product['id']] = {} : null
            state.products[product['id']]['variants'] == undefined ? state.products[product['id']]['variants'] = {} : null
            state.products[product['id']]['variants'][variant['id']] = variant
        },
        addVariant(state, payload) {
            state.products[state.current_product]['variants'] == undefined ? state.products[state.current_product]['variants'] = {} : null
            state.products[state.current_product]['variants'][payload['id']] = payload
        },
        clearCurrentProduct(state) {
            state.current_product = ''
        },
        setVariantProperty (state, payload) {
            let variantId = payload['variantId']
            if (state.products[state.current_product] && state.products[state.current_product]['variants'][variantId]) {
                let newProducts = cloneDeep(state.products)
                let property = newProducts[state.current_product]['variants'][variantId]['variant_properties_without_filters'].find(prop => prop.id === payload.id)
                if (property) {
                    property.value = payload.value
                    state.products = newProducts
                }
            }
        },
        setVariantAttribute (state, payload) {
            let variantId = payload['variantId']
            let attributeName = payload['name']
            let attributeValue = payload['value']

            if (state.products[state.current_product] && state.products[state.current_product]['variants'][variantId]) {
                let newProducts = cloneDeep(state.products)
                newProducts[state.current_product]['variants'][variantId][attributeName] = attributeValue

                state.products = newProducts
            }
        }
    },
    actions: {
        setCurrentProduct({ commit }, payload) {
            commit('setCurrentProduct', payload)
        },
        initProduct({ commit }, payload) {
            commit('initProduct', payload)
        },
        addVariant({ commit }, payload) {
            commit('addVariant', payload)
        },
        clearCurrentProduct({ commit }) {
            commit('clearCurrentProduct')
        },
        setVariantProperty({ commit }, payload) {
            commit('setVariantProperty', payload)
        },
        setVariantAttribute({ commit }, payload) {
            commit('setVariantAttribute', payload)
        }
    }
}

export {
    productData
}

import 'twix'
import moment from 'moment'
import { upperFirst, get, flatten } from 'lodash'
import shippingCategories from '@/../shared/constants/shippingCategories'

function getShippingCategoryLabel (name) {
    return get(shippingCategories.find(e => e.name === name), 'label')
}

function getBreadcrumbFromPermalink (permalink) {
    return permalink
        .replace(/\//g, ' / ')
        .replace(/-and-/g, ' & ')
        .replace(/-/g, ' ')
        .replace(/[^\s]+/g, upperFirst)
}

function getValueFromPermalink (permalink) {
    return permalink.replace(/\//g, ' > ')
}

function getNormalizedPhone (phone) {
    return phone?.replace(/(^\+1|[^\d])/g, '')
}

function getDeliveryWindowDate (deliveryTime) {
    deliveryTime = flatten([deliveryTime])
    let deliveryTimeFrom = getMomentDate(deliveryTime[0])
    let deliveryTimeTo = getMomentDate(deliveryTime[1])

    if (deliveryTimeFrom && deliveryTimeTo && deliveryTimeFrom.unix() !== deliveryTimeTo.unix()) {
        return deliveryTimeFrom.twix(deliveryTimeTo).format({ implicitYear: false })
    } else if (deliveryTimeFrom) {
        return deliveryTimeFrom.format('MMM D, YYYY, HH:mm A')
    } else {
        return deliveryTime[0]
    }
}

function getMomentDate (date) {
  if (!date) { return null }

  let momentDate = moment(date)
  if (!momentDate.isValid()) { return null }

  return momentDate
}

function getVisibleEstAvailabilityText (date) {
    if (isPastDate(date)) return 'Contact us for availability'

    return date
}

function isPastDate (date) {
    const momentDate = moment.utc(date, 'MMM D, YYYY')
    if (!momentDate.isValid()) return false

    const currentDate = moment()
    const weekDiff = currentDate.diff(date, 'weeks')

    return weekDiff >= 2
}

export {
    isPastDate,
    shippingCategories,
    getShippingCategoryLabel,
    getBreadcrumbFromPermalink,
    getNormalizedPhone,
    getDeliveryWindowDate,
    getMomentDate,
    getValueFromPermalink,
    getVisibleEstAvailabilityText
}

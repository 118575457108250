<template>
    <transition name="fade">
        <div id="sn-modal"
             class="modal"
             :class="[...getClasses, { active: getState, 'max-size': getMaxSize }]"
             @click="hideModal"
             :key="getState">
            <div class="modal-content">
                <component :is="getComponent"
                           v-bind="getProps"
                           v-on="getListeners"/>
            </div>
        </div>
    </transition>
</template>

<script>
    // products chunk
    const AddedToCart = () => import(/* webpackChunkName: "products" */ '@/components/AddedToCart')
    // home chunk
    const AddDiscountCode = () => import(/* webpackChunkName: "home" */ '@/components/AddDiscountCode')
    // checkout chunk
    const ShippingCartEdit = () => import(/* webpackChunkName: "checkout" */ '@/components/ShippingCartEdit')
    const DeliveryInformation = () => import(/* webpackChunkName: "checkout" */ '@/components/DeliveryInformation')
    const CscCodeBack = () => import(/* webpackChunkName: "checkout" */ '@/components/CscCodeBack')
    const CscCodeFront = () => import(/* webpackChunkName: "checkout" */ '@/components/CscCodeFront')
    const PaymentError = () => import(/* webpackChunkName: "checkout" */ '@/components/PaymentError')
    import { mapGetters } from 'vuex'
    import modalBackgroundScrollBlock from '@/mixins/modalBackgroundScrollBlock'


    export default {
        name: 'modal',
        components: {
            SignForm: () => import('@/components/SignForm'),
            AddedToCart,
            AddDiscountCode,
            ShippingCartEdit,
            DeliveryInformation,
            CscCodeBack,
            CscCodeFront,
            ConfirmationAlert: () => import('@/../shared/components/ConfirmationAlert'),
            PaymentError,
            Information: () => import('@/../shared/components/Information'),
            ImagePinProductSearch: () => import('@/../shared/components/rooms/ImagePinProductSearch'),
            PaymentsEditBillingAddress: () => import('@/components/payments/PaymentsEditBillingAddress'),
            ParcelDetailsModal: () => import('@/../shared/components/orders/ParcelDetailsModal'),
            NonReturnableInfoModal: () => import('@/components/NonReturnableInfoModal'),
            AddGiftCardPaymentModal: () => import('@/components/gift_cards/AddGiftCardPaymentModal'),
            GiftCardBalanceModal: () => import('@/components/gift_cards/GiftCardBalanceModal'),
            EditProductModal: () => import('@/components/EditProductModal'),
            QuickShop2: () => import('@/components/ab_tests/QuickShop2'),
            AlternativeImageTextModal: () => import('@/../shared/components/AlternativeImageTextModal'),
            ProductsShareViaEmailModal: () => import('@/components/products/ProductsShareViaEmailModal'),
            ProductsUploadUserPhotoModal: () => import('@/components/products/ProductsUploadUserPhotoModal'),
            ProductsUploadUserPhotoSignInModal: () => import('@/components/products/ProductsUploadUserPhotoSignInModal'),
            DesignerTipModal: () => import('@/components/products/DesignerTipModal'),
            MarketingContestShareModal: () => import('@/components/MarketingContestShareModal')
        },
        mixins: [modalBackgroundScrollBlock],
        data () {
            return {
                modalConditionNames: ['getState']
            }
        },
        computed: {
            ...mapGetters('modal', [
                'getState',
                'getClasses',
                'getState',
                'getMaxSize',
                'getComponent',
                'getProps',
                'getListeners'
            ])
        },
        methods: {
            hideModal(event) {
                let modal = document.getElementById('sn-modal')
                if (event.target == modal) {
                    this.$store.dispatch('modal/hideModal')
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/stylesheets/animations/fade';

    .modal{
        cursor: pointer;
        display: none;
        position: fixed;
        z-index: 99999;
        padding-top: 10px;
        left: 0;
        overflow: auto;
        background-color: rgb(0,0,0);
        background-color: rgba(0,0,0,0.3);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        &.active{
            display: block;
        }

        .modal-content{
            background: #fff;
            border-radius: 1px;
            width: 480px;
            cursor: default;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &.max-size {
            display: grid;
            justify-content: center;
            align-items: center;
            padding: 0;

            .modal-content {
                margin: 15px;
                width: 91vw;
                max-width: 1500px;
                position: static;
                transform: none;
                position: relative;
                top: initial;
                left: initial;
            }
        }

        &.auto-width {
            .modal-content {
                width: fit-content;
            }
        }

        &.center-bottom {
            .modal-content {
                transform: none;
                bottom: -140px;
                top: 70%;
                left: 0;
                border-radius: 30px;
                position: absolute;
            }
        }

      @media only screen and (max-width: 768px) {
        &.mobile-bottom {
          display: flex;

          .modal-content {
            align-self: flex-end;
            top: unset;
            left: unset;
            transform: none;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
          }
        }
      }

        @media only screen and (max-width: 480px) {
            .modal-content{
                max-width: 100%;
            }
        }

        @media only screen and (max-width: 600px) {
            padding-top: 0px;
            overflow: auto;
        }


        @media only screen and (min-width: 600px) and (max-width: 768px) {
            padding-top: 0px;
        }


        @media only screen and (min-width: 768px) and (max-width: 992px) {
            padding-top: 0px;
        }


        @media only screen and (min-width: 992px) and (max-width: 1500px) {

        }
    }

</style>

import ApiOrder from '@/api/Order'
import { trim, toLower } from 'lodash'

export default function ({ store, $axios }) {
  function discountGenerationParams () {
    return { code: store.getters['order/getUrlCampaign'] }
  }

  function shouldGenerateDiscount () {
    return (
      !!store.getters['order/getOrder'] &&
      !!store.getters['order/getUrlCampaign'] &&
      !isPromotionApplied()
    )
  }

  function isPromotionApplied () {
    let code = store.getters['order/getUrlCampaign'] || ''
        code = trim(code)
        code = toLower(code)

    return store.getters['order/getDiscountsAdjustments']
                .some(adjustment => adjustment.is_promotion && adjustment.label === code)
  }

  return function generateDiscount () {
    console.log(shouldGenerateDiscount())
    if (!shouldGenerateDiscount()) return

    ApiOrder.generateDiscount($axios, store.getters['order/getOrderId'], discountGenerationParams())
      .then(response => store.dispatch('order/setOrder', response.data.data))
      .catch(error => console.error(error))
  }
}